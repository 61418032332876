// Usage: Select element needs the following attributes
//        - the 'data-page-reloader' attribute
//          to correctly bind the change functionality.
//        - the 'name' attribute with the name of the
//          param key you want to submit with your request.
//

SelectTagPageReloader = {
  init: function() {
    var selectTag = $('select[data-page-reloader]');

    this.bindChange(selectTag);
  },

  bindChange: function(target) {
    var paramName = target.attr('name');

    target.change($.proxy(function() {
      var value = target.find('option:selected').attr('value');
      window.location = window.location.pathname + this.queryParam(paramName, value);
    }, this));
  },

  queryParam: function(name, value) {
    return "?" + name + "=" + value;
  }
};
