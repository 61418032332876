
SkuModelAccordion = {
  // collapsible accordion
  init:function() {
    var category_links = $('.category_link');
    var model_links = $('.model_link');
    category_links.on('click',function(event){
      var clicked_element = $('div[data-parent-id=' + $(this).data('id')+']');
      if (clicked_element.is(":hidden")){
        model_links.addClass('is-hidden'); // hide all model  links
        clicked_element.removeClass('is-hidden'); // remove hidden from clicked category link
      }
      else {model_links.addClass('is-hidden');} // hide all model  links
      event.preventDefault();
    })
  }
};